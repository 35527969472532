body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


*{
    letter-spacing: 0.05em;

    margin: 0;
    padding: 0;
    outline: 0;
    color: #4d5346;
    font-family:   'Roboto', sans-serif;
}

body {
    background: linear-gradient(-65deg, #332392, #B25D31,  #fbe16d, #0c7261);
    background-size: 150% 200%;
    -webkit-animation: gradient 15s ease infinite;
            animation: gradient 15s ease infinite;
    background-repeat: no-repeat;
    background-attachment: fixed;
    height: 100vh;
    margin: 0;
    padding: 0;
}

@-webkit-keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}



a{

    color: rgb(6, 61, 137);
}

h1{
    font-size: 170%;

}

h2 {
    text-align: center;
    color: #2f363b;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: subpixel-antialiased;
}

h4{
    padding-top: 3%;
    padding-right: 5%;
    font-weight:normal;

}

h3{
    text-align: center;
}


h6 {
    text-align: justify;
}


h5{
    padding-top: 8%;
    font-size:xx-small;
    font-weight: normal;
    text-align: left;
}


#marketing-consent{
    float: left; 
    margin-top: 2%;
    font-size: smaller;
}

#consent{
    padding: 2px;
    font-size: smaller;
}

p {
    padding-top: 1%;
    text-transform: uppercase;
    text-align: center;
    line-height: 1.5em;
}


span {
    font-size: xx-small;

}

.logo {
    padding-left: 20px;
    width: 180px;
    margin-left: 10px;
}

.top-navigation {
    max-height: 10vh;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #19221e;
    padding: 20px 10px;
    box-shadow: 0px 0.5px 0.5px 0px rgba(49, 49, 49, 0.5)
}



.form{
    padding: 40px 20px;
  
}

form{
    background-color: white;
}

.container {
    width: 100%;
    min-height: 60vh;
    background-color: rgba(0, 0, 0, 0.05);
    display: flex;
    justify-content: center;
    
}

section {
    display: flex;
    justify-content: space-between;
    width: 85%;
    max-width: 960px;
    margin: 30px 0;
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 2px 5px 0 #454545;
}

article {
    text-align: block;
    padding-left: 2%;
    width: 45%;
    background-color: #87A086;
    display: flex;
}

aside {
    width: 70%;

}

div#container section aside h2 {
    font-size: 40px;
    color: #282828;
    font-weight: 700;
}

div#container section aside p {
    font-size: 20px;
    color: #282828;
}

form {
    width: 90%;
    max-width: 350px;
    margin: 30px auto 0 auto;
}

form input[type=text],
form input[type=email] {
    width: calc(100% - 55px);
    border: none;
    padding: 12px;
    margin: 8px 0;
    font-size: 12px;
    border-radius: 8px;
    border: 1px solid rgb(234, 234, 234);
    background-color: #e9f2fb;
    background-position: 4% 50%;
    background-repeat: no-repeat;
    background-size: 18px;
}


@media(max-width: 760px) {
    section {
        flex-flow: column;
    }

    article{
        padding-top: 5%;
        width: 100%;
    }

    aside {
        width: 100%;
    }
}


label {
    font-family: 'Roboto', sans-serif;
    padding-top: 3%;
}


.field {
    display: flex;
    flex-direction: column;
}

.name-container {
    display: flex;
    grid-gap: 5%;
    gap: 5%;
}

.name {
    flex-grow: 1;
}

.accept-container {
    display: flex;
    flex-direction: row;
    text-align: block;
}

.checkbox{
    padding-top: 2%;
    padding-right: 3%;
   }

#submit-button{
    padding-top: 3%;
    width: 140px;
    height: 45px;
    font-family: 'Roboto', sans-serif;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    font-weight: 500;
    color: WHITE;
    background-color: #6a9c80;
    border: none;
    border-radius: 45px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
}


#submit-button:hover {
    background-color: #0e8e34;
    box-shadow: 0px 15px 20px rgba(8, 163, 36, 0.4);
    color: rgb(255, 255, 255);
    -webkit-transform: translateY(-3px);
            transform: translateY(-3px);
}

.button-container {
    height: 50px;
    position: relative;
    padding: 5%;
}

.center {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.title{
    padding: 3%;
}

.modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    pointer-events: none;
  }
  
  .modal.enter-done {
    opacity: 1;
    pointer-events: visible;
  }
  
  .modal.exit {
    opacity: 0;
  }
  
  .modal-content {
    font-family:   'Roboto', sans-serif;;
    width: 600px;
    background-color: #fff;
    transition: all 0.3s ease-in-out;
    -webkit-transform: translateY(-200px);
            transform: translateY(-200px);
    padding: 3%;
  }
  
  .modal.enter-done .modal-content {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  
  .modal.exit .modal-content {
    -webkit-transform: translateY(-200px);
            transform: translateY(-200px);
  }

  .modal-button{
      justify-content: right;
      font-weight: bold;
      font-size: xx-large;
  }
  

  
  .modal-body {
    padding: 5%;
    border-top: 1px solid rgb(255, 255, 255);
    border-bottom: 1px solid rgb(255, 255, 255);
  }

  footer{
      width: 100%;
      bottom:0;
      position: flex;
      margin-top: auto;
      background-color: #19221e;
  }
  
  .footer-container{
    background-color: #19221e;
    width: 100%;
	max-width: 1170px;
	margin:auto;


}
.footer-row{
	display: flex;
	flex-wrap: wrap;
}
ul{
	list-style: none;
}
.footer{
	background-color: #24262b;
    padding: 70px 0;
}
.footer-col{
   width: 25%;
   padding: 0 15px;
}
.footer-col h4{
	font-size: 18px;
	color: #ffffff;
	text-transform: capitalize;
	margin-bottom: 35px;
	font-weight: 500;
	position: relative;
}
.footer-col h4::before{
	content: '';
	position: absolute;
	left:0;
	bottom: -10px;
	background-color: #c2af5b;
	height: 2px;
	box-sizing: border-box;
	width: 50px;
}
.footer-col ul li:not(:last-child){
	margin-bottom: 10px;
}
.footer-col ul li a{
	font-size: 14px;
	text-transform: capitalize;
	color: #ffffff;
	text-decoration: none;
	font-weight: 300;
	color: #bbbbbb;
	display: block;
	transition: all 0.3s ease;
}
.footer-col ul li a:hover{
	color: #ffffff;
	padding-left: 8px;
}
.footer-col .social-links a{
	display: inline-block;
	height: 40px;
	width: 40px;
	background-color: rgba(255, 255, 255, 0.01);
	margin:0 10px 10px 0;
	text-align: center;
	line-height: 40px;
	border-radius: 50%;
	color: #ffffff;
	transition: all 0.5s ease;
}
.footer-col .social-links a:hover{
	color: #24262b;
	background-color: #ffffff;
}

/*responsive*/
@media(max-width: 767px){
  .footer-col{
    width: 50%;
    margin-bottom: 30px;
}
}
@media(max-width: 574px){
  .footer-col{
    width: 100%;
}
}



